// TODO: This whole package is doing too much:
//
// A: Authentication
//   1. signs users in (credentials and social media)
// B. Account management
//   1. migration (whatever this means)
//
// At some point, we should split this up and make it less
// schizophrenic.

import QueryString from 'query-string'

import {
  all,
  call,
  put,
  race,
  take,
  takeLatest,
} from 'redux-saga/effects'

import {
  Cookie,
  GtmAPI,
} from '@termly_web/common'

import PATHS from 'constants/paths'

import {
  signInSocial,
  signInCreateAccount,
  signInMigrateAccount,
} from 'constants/api'

import history from 'store/history'

import Client from 'utils/client'
import errorArrayGenerator from 'utils/errorArrayGenerator'
import decodeQuery from 'utils/decodeQuery'

import * as Actions from './actions'


export default function* authenticationSagas() {
  yield all([
    call(watchAuthentication),

    takeLatest(Actions.signInCreateAccount, createAccount),
    takeLatest(Actions.signInMigrateAccount, migrateAccount),

    takeLatest(Actions.signInSuccess, handleSignInSuccess),
    takeLatest(Actions.signUpSuccess, handleSignUpSuccess),
  ])
}

export const _private = {
  createAccount,
  handleSignInSuccess,
  handleSignUpSuccess,
  migrateAccount,
  socialLogin,
  tellGTM,
  watchAuthentication,
}

function* socialLogin({ payload }) {
  const {
    provider,
    redirect,
    ...postArgs
  } = payload

  try {
    const query = QueryString.parse(window.location.search)
    const deQuery = decodeQuery(query)

    const { data: user } = yield call(Client.post, signInSocial(provider), {
      ...postArgs,
      ...deQuery,
    })

    const successAction = ( postArgs.mode === 'sign_in' )
      ? Actions.signInSuccess
      : Actions.signUpSuccess

    yield put(successAction({
      redirect,
      user,
    }))
  } catch (e) {
    const {
      email,
      identity_id,
    } = e.response.data

    if ( e.response.status === 404 ) {
      yield call(history.push, `${ PATHS.CREATE_ACCOUNT }?email=${ email }&identity_id=${ identity_id }`)
    } else if ( e.response.status === 412 ) {
      yield call(history.push, `${ PATHS.MIGRATE_ACCOUNT }?email=${ email }&identity_id=${ identity_id }&provider=${ provider }`)
    } else {
      const errors = errorArrayGenerator({ email, identity_id })
      yield put(Actions.signInError(errors))
    }
  }
}

function* createAccount({ payload }) {
  const {
    identity_id: identityID,
    redirect,
    ...putArgs
  } = payload

  try {
    const { data: user } = yield call(Client.put,
                                      signInCreateAccount(identityID),
                                      putArgs)

    yield put(Actions.signUpSuccess({
      redirect,
      user,
    }))
  } catch (e) {
    yield put(Actions.signInError(e.response.data.error))
  }
}

function* migrateAccount({ payload }) {
  const {
    identity_id: identityID,
    redirect,
    ...putArgs
  } = payload

  try {
    const { data: user } = yield call(Client.put,
                                      signInMigrateAccount(identityID),
                                      putArgs)

    yield put(Actions.signInSuccess({
      redirect,
      user,
    }))
  } catch (e) {
    yield put(Actions.signInError(e.response.data.error))
  }
}

function* watchAuthentication() {
  while ( true ) {
    // This used to contain signInUser. I'm just going to leave
    // the race() here even though it's no longer necessary, just
    // to minimize the amount of code shuffling I have to do here.
    // Eventually, this'll all go away anyway.
    const winner = yield race({
      socialAction: take(Actions.signInSocial),
    })

    const {
      socialAction,
    } = winner

    if ( socialAction ) {
      yield call(socialLogin, socialAction)
    }
  }
}

function* handleSignInSuccess(action) {
  yield call(tellGTM, action)
}

function* handleSignUpSuccess(action) {
  yield call(tellGTM, action)

  // TODO: What in the world is this all about?
  yield call(Cookie.setCookie, {
    TEST_0802: 1,
  })
}

function* tellGTM({ type, payload }) {
  yield call(GtmAPI.push, {
    event: type,
    payload: payload.user,
  })
}
