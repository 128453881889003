import { AuthenticationActions } from 'pkg/authentication'

import Paths from 'constants/paths'

import {
  UPDATE_COOKIE_POLICY_SUCCESS,
} from 'reducers/dashboard'


/**
 * @param {Object} action - a Redux action
 * @param {Object} action.payload
 * @param {String} action.type
 */
export default function getURLForAction({ payload, type }) {
  switch (type) {
    case UPDATE_COOKIE_POLICY_SUCCESS:
      return `${ Paths.COOKIE_REPORT(payload.website_uuid) }?step=1`

    case `${ AuthenticationActions.signUpSuccess }`:
    case `${ AuthenticationActions.signInSuccess }`: {
      const {
        redirect,
        user,
      } = payload

      if ( user.must_change_password ) {
        return Paths.ACCOUNT
      }

      return redirect || Paths.DASHBOARD
    }
  }
}
