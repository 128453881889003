import {
  call,
  take,
} from 'redux-saga/effects'

import { AuthenticationActions } from 'pkg/authentication'

import { editDocLink } from 'constants/api'

import {
  UPDATE_COOKIE_POLICY_SUCCESS,
} from 'reducers/dashboard'

import getURLForAction from './getURLForAction'

const TAKE_ACTIONS = [
  AuthenticationActions.signInSuccess,
  AuthenticationActions.signUpSuccess,
  UPDATE_COOKIE_POLICY_SUCCESS,
]


// TODO: I'm not sure I like this whole idea of having a centralized
// saga for doing redirects. This seems to fly in the face of a
// modular design. The more I think about it, the more I think I hate
// it, but I don't have time right now to do anything about this;
// undoing this saga would be quite an undertaking.
//
export function* watchRedirect() {
  while ( true ) {
    const action = yield take(TAKE_ACTIONS)

    const url = yield call(getURLForAction, action)

    yield call([window.location, window.location.assign], url)
  }
}

// At some point, this constant was exported by
// 'reducers/documentPreview', but it was removed and nothing else in
// this entire project is referring to it, so it's effectively
// unreachable code.
//
// TODO: Figure out what happened and fix this.
const MIGRATE_POLICY_SUCCESS = 'MIGRATE_POLICY_SUCCESS'

const linkActionList = [MIGRATE_POLICY_SUCCESS]

export function* watchBuilderRedirect() {
  while (true) {
    const action = yield take(linkActionList)
    let path
    switch (action.type) {
      case MIGRATE_POLICY_SUCCESS: {
        const { website_id, id } = action.payload
        path = editDocLink(website_id, id)
        break
      }
    }
    window.location.href = path
  }
}
